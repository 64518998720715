import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDemoArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={36}
      fill="none"
      viewBox="0 0 28 36"
      {...props}
    >
      <path
        fill="#fff"
        stroke="#000"
        d="m.781 17.267.001-.001L15.18 1.268c.214-.236.509-.367.82-.367h9.6c.432 0 .826.254 1.005.65.177.401.102.865-.186 1.183v.001l-13.437 14.93-.301.335.301.334 13.436 14.927c.292.325.365.79.188 1.182v.001c-.18.4-.574.655-1.007.655H16a1.121 1.121 0 0 1-.823-.367L.782 18.737a1.101 1.101 0 0 1 0-1.47Z"
      />
    </svg>
  );
};
export default SvgDemoArrow;
