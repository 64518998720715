import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#AAA"
        d="M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12v1.45c0 .983-.337 1.82-1.012 2.513C20.313 16.654 19.483 17 18.5 17a3.74 3.74 0 0 1-1.65-.375 3.523 3.523 0 0 1-1.3-1.075 4.813 4.813 0 0 1-1.638 1.087A5.132 5.132 0 0 1 12 17c-1.383 0-2.563-.488-3.537-1.463C7.488 14.563 7 13.383 7 12s.487-2.563 1.463-3.537C9.438 7.488 10.617 7 12 7s2.563.487 3.537 1.463C16.512 9.438 17 10.617 17 12v1.45c0 .433.142.8.425 1.1.283.3.642.45 1.075.45.433 0 .792-.15 1.075-.45.283-.3.425-.667.425-1.1V12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20h5v2h-5Zm0-7c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 15 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 12 9c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 9 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 12 15Z"
      />
    </svg>
  );
};
export default SvgEmailIcon;
