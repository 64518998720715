import styled from 'styled-components/macro';

import { device } from 'components/Global/MediaQuery';

export const ScContainer = styled.div`
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px 16px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

interface ScWrapProps {
  noPadding?: boolean;
  bordered?: boolean;
  spaceBetween?: boolean;
  topPadding?: string;
  topMargin?: string;
  rightMargin?: string;
  bottomPadding?: string;
  alignLeft?: boolean;
  vertical?: boolean;
  maxWidth?: number;
  fullWidth?: boolean;
}
export const ScWrap = styled.div<ScWrapProps>`
  ${({ bordered }) => bordered && `border-top: 1px solid grey;`};
  justify-content: ${({ spaceBetween, alignLeft }) =>
    spaceBetween ? 'space-between' : alignLeft ? 'left' : 'center'};
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? `column` : `row`)};
  flex-wrap: wrap;
  align-items: center;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `auto`)};
  width: ${({ fullWidth }) => (fullWidth ? `100%` : `auto`)};
  ${({ topPadding }) => topPadding && `padding-top: ${topPadding}px;`};
  ${({ topMargin }) => topMargin && `margin-top: ${topMargin}px;`};
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`};
  ${({ bottomPadding }) =>
    bottomPadding && `padding-bottom: ${bottomPadding}px;`};
  button,
  a {
    margin: 0;
    &:nth-child(2) {
      ${({ vertical }) =>
        vertical ? `margin-top: 20px;` : `margin-right: 15px;`};
      @media (${device.tablet}) {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }

  @media (${device.tablet}) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  ${({ vertical }) =>
    vertical
      ? `@media (${device.mobileL}) {button{width: 100%; margin: 0 0 5px 0;}}`
      : null};
  @media (${device.mobileL}) {
    a:first-child {
      margin-right: 5px;
    }
  }
`;
