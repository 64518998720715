import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from 'components/UI/Block';
import { Button } from 'components/Button';
import { TimeoutError } from 'components/ThemedComponents/Errors';
import {
  Failed,
  InProcess,
  Success,
} from 'components/ThemedComponents/Results';

import { VerificationResultStatuses } from 'helpers/enums';

import { ScResult } from './styled';

interface ResultScreenProps {
  textPrimaryButton?: string;
  onClickPrimaryButton?: () => void;
  textSecondaryButton?: string;
  onClickSecondaryButton?: () => void;
  text?: string;
  pending?: boolean;
  resultStatus?: VerificationResultStatuses;
}

export const ResultScreen: FC<ResultScreenProps> = ({
  textPrimaryButton,
  onClickPrimaryButton,
  textSecondaryButton,
  onClickSecondaryButton,
  text,
  pending,
  resultStatus = VerificationResultStatuses.PROCESSING,
}) => {
  const { t } = useTranslation();

  const { image, title } = useMemo(() => {
    switch (resultStatus) {
      case VerificationResultStatuses.PROCESSING:
        return pending
          ? {
              image: <InProcess />,
              title: t('common:result.processing.title'),
            }
          : {
              image: <InProcess />,
              title: t('common:result.processed.title'),
            };
      case VerificationResultStatuses.SUCCESS:
        return {
          image: <Success />,
          title: t('common:result.success.title'),
        };
      case VerificationResultStatuses.FAILED:
        return {
          image: <Failed />,
          title: t('common:result.failed.title'),
        };

      case VerificationResultStatuses.EXPIRED:
        return {
          image: <TimeoutError />,
          title: t('common:result.expired.title'),
        };
    }
  }, [t, resultStatus, pending]);

  const button = useMemo(() => {
    return onClickPrimaryButton || onClickSecondaryButton ? (
      <>
        {onClickSecondaryButton && (
          <Button transparent onClick={onClickSecondaryButton}>
            {textSecondaryButton || t('common:tryAgain')}
          </Button>
        )}
        {onClickPrimaryButton && (
          <Button onClick={onClickPrimaryButton}>
            {textPrimaryButton || t('common:tryAgain')}
          </Button>
        )}
      </>
    ) : null;
  }, [
    t,
    onClickPrimaryButton,
    onClickSecondaryButton,
    textPrimaryButton,
    textSecondaryButton,
  ]);

  return (
    <Block>
      <ScResult>
        {image}

        <h1>{title}</h1>

        {text && <p className="text">{text}</p>}

        {button && !pending ? (
          <div className="button-block">{button}</div>
        ) : null}
      </ScResult>
    </Block>
  );
};
