export enum MediaType {
  MOBILE_S,
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum MediaQuery {
  MOBILE_S = '(max-width: 576px)',
  MOBILE = '(min-width: 576px) and (max-width: 768px)',
  TABLET = '(min-width: 768px) and (max-width: 928px)',
  DESKTOP = '(min-width: 928px)',
}

export enum AppTheme {
  DEFAULT = 'dark',
  LIGHT = 'light',
  KVITUM = 'kvitum',
  EWALLET = 'ewallet',
  BITSTORE = 'bitstore',
  ROCKETCHANGE = 'rocketchange',
  PLATYMO = 'platymo',
  TOPRATE = 'toprate',
}

export enum ProvidersProtocol {
  VCHECK = 'vcheck',
}

export enum VerificationScheme {
  FULL_CHECK = 'full_check',
  DOCUMENT_UPLOAD_ONLY = 'document_upload_only',
  LIVENESS_CHALLENGE_ONLY = 'liveness_challenge_only',
}

export enum VerificationStatuses {
  CREATED,
  INITIALIZED,
  WAITING_USER_INTERACTION,
  WAITING_POSTPROCESSING,
  IN_POSTPROCESSING,
  WAITING_MANUAL_CHECK,
  FINALIZED,
  EXPIRED,
}

export enum VerificationResultStatuses {
  PROCESSING,
  EXPIRED,
  SUCCESS,
  FAILED,
}

export enum VerificationStageTypes {
  DOCUMENT_UPLOAD,
  LIVENESS_CHALLENGE,
  SURVEY = 3,
}

export enum VerificationStageErrors {
  VERIFICATION_NOT_INITIALIZED = 0,
  USER_INTERACTED_COMPLETED = 1,
  VERIFICATION_EXPIRED,
  PROVIDER_NOT_INITIALIZED,
}

export enum VerificationRejectionReason {
  INVALID_DOCUMENT = 'invalid_document',
  LIVENESS_NOT_PASSED = 'liveness_not_passed',
  IDENTITY_VERIFICATION_FAILED = 'identity_verification_failed',
  DOCUMENT_NUMBER_MATCH = 'document_number_match',
  DOCUMENT_NAME_SURNAME_DOB_MATCH = 'document_name_surname_dob_match',
  DOCUMENT_PHOTO_MATCH = 'document_photo_match',
  USER_IP_MATCH = 'user_ip_match',
  SANCTION_PERSON_MATCH = 'sanction_person_match',
  EXPIRED = 'expired',
}

//Documents upload

export enum DocumentCategory {
  PASSPORT = 0,
  FOREIGN_PASSPORT = 1,
  ID_CARD = 2,
}

export enum DocumentFieldType {
  text = 'text',
  textLatin = 'text_latin',
  textCyrillic = 'text_cyrillic',
  date = 'date',
}

export enum DocumentErrorCode {
  VERIFICATION_NOT_INITIALIZED,
  USER_INTERACTED_COMPLETED,
  STAGE_NOT_FOUND,
  INVALID_STAGE_TYPE,
  PRIMARY_DOCUMENT_EXISTS,
  UPLOAD_ATTEMPTS_EXCEEDED,
  INVALID_DOCUMENT_TYPE,
  INVALID_PAGES_COUNT,
  INVALID_FILES,
  PHOTO_TOO_LARGE,
  PARSING_ERROR,
  INVALID_PAGE,
  FRAUD,
  BLUR,
  PRINT,
}

//Liveness

export enum Destination {
  Left = 'left_turn',
  Right = 'right_turn',
  Up = 'up_turn',
  Down = 'down_turn',
}

export enum Mode {
  DEMO,
  FOCUS,
  DESTINATION,
}

export enum LivenessGesture {
  UP = 'up',
  DOWN = 'down',
  RIGHT = 'right',
  LEFT = 'left',
  MOUTH = 'mouth',
  STRAIGHT = 'straight',
  BLINK = 'blink',
}

export enum LivenessEvent {
  NEW_FACE = 'new_face',
  NO_FACE = 'no_face',
  NEXT_GESTURE = 'next_gesture',
  START = 'start',
  FAILURE = 'failure',
  SUCCESS = 'success',
  PROCESSING = 'processing',
}

export enum LivenessFailureReason {
  FACE_NOT_FOUND = 'face_not_found',
  MULTIPLE_FACES = 'multiple_faces',
  TIMEOUT = 'timeout',
  FAST_MOVEMENT = 'fast_movement',
  TOO_DARK = 'too_dark',
  DISCONNECTED = 'disconnected',
  NOT_SAME_PERSON = 'not_same_person',
  INVALID_MOVEMENTS = 'invalid_movements',
}

export enum LivenessChallengeStatus {
  INITIALIZED = 0,
  RUNNING = 1,
  SUCCESS = 2,
  FAIL = 3,
  ERROR = 4,
}

export enum LivenessEventError {
  CAMERA = 'camera',
  EXCEEDED_ATTEMPTS = 'exceeded_attempts',
  DISCONNECTED = 'disconnected',
  UNKNOWN = 'unknown',
}
