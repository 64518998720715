import { createGlobalStyle } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';
import { Theme } from 'themes/contracts';

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        overscroll-behavior-y: none;
        min-height: 100dvh;
        font-family: ${({ theme }) => theme.mainFontFamilyName};
        font-weight: 400;
        color: ${({ theme }) => theme.baseContent};
        font-size: 16px;
        line-height: 1.5;
      ${({ theme }) =>
        theme?.background
          ? `background:url(${theme.background}) no-repeat center center fixed;`
          : null}
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-color: ${({ theme }) => theme.base};
        @media (${device.mobileXL}) {
          font-size: 14px;
          line-height: 20px;
          min-height: 100dvh;
        }
    }

    #root {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: stretch;
      @media (${device.mobileXL}) {
        gap: 16px;
      }
    }

    button, input, textarea {
        font-family: ${({ theme }) => theme.mainFontFamilyName};
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
`;
