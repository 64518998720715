import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from 'components/UI/Block';
import { Button } from 'components/Button';
import { DefaultError } from 'components/ThemedComponents/Errors';

import { ScResult } from './styled';

const InternalError = () => {
  const { t } = useTranslation('common');

  return (
    <Block>
      <ScResult>
        <DefaultError />
        <h1>{t('errors.internal.title')}</h1>
        <div className="text">
          <p>{t('errors.internal.text')}</p>
        </div>
        <div className="button-block" onClick={() => window.location.reload()}>
          <Button>{t('errors.internal.button')}</Button>
        </div>
      </ScResult>
    </Block>
  );
};

export default memo(InternalError);
