import styled, { css } from 'styled-components/macro';

import { device } from 'components/Global/MediaQuery';
import { Link } from 'react-router-dom';

export interface ScButtonProps {
  transparent?: boolean;
  asLink?: boolean;
}

export const ScButton = styled.button<ScButtonProps>`
  min-width: 248px;
  width: max-content;
  padding: ${({ asLink }) => (asLink ? '0' : '16px')};
  border-radius: ${({ asLink }) => (asLink ? '0' : '4px')};
  font: ${({ asLink }) => (asLink ? '500 14px/16px' : '700 16px/18px')}
    ${({ theme }) => theme.mainFontFamilyName};
  line-height: 1.12;
  text-decoration: ${({ asLink }) => (asLink ? 'underline' : 'none')};
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  text-transform: initial;
  @media (${device.mobileXXL}) {
    min-width: 100%;
    width: 100%;
  }
  .icon {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 8px;
    svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-55%);
    }
  }

  ${({ transparent, asLink }) => {
    switch (true) {
      case transparent != null:
        return css`
          min-height: 56px;
          background-color: transparent;
          border: 1px solid ${({ theme }) => theme.neutral};
          color: ${({ theme }) => theme.neutral};
          svg {
            path {
              fill: ${({ theme }) => theme.neutral};
            }
          }
          &:hover {
            background-color: ${({ theme }) => theme.neutralHover};
          }
          &:active {
            background-color: ${({ theme }) => theme.neutralActive};
          }

          &:disabled {
            background-color: transparent;
            color: ${({ theme }) => theme.disabled};
            border: 1px solid ${({ theme }) => theme.disabled};
            cursor: not-allowed;
          }
        `;

      case asLink != null:
        return css`
          width: auto;
          background-color: transparent;
          color: ${({ theme }) => theme.baseContent};
          border: none;
          font-weight: 600;
          svg {
            path {
              fill: ${({ theme }) => theme.baseContent};
            }
          }
          &:disabled {
            color: ${({ theme }) => theme.disabled};
            cursor: not-allowed;
          }
        `;

      default:
        return css`
          min-height: 56px;
          background-color: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.primaryContent};
          border: none;
          svg {
            path {
              fill: ${({ theme }) => theme.primaryContent};
            }
          }

          &:hover {
            background-color: ${({ theme }) => theme.primaryHover};
          }
          &:active {
            background-color: ${({ theme }) => theme.primaryActive};
          }

          &:disabled {
            background-color: ${({ theme }) => theme.disabled};
            color: ${({ theme }) => theme.disabledContent};
            cursor: not-allowed;
          }
        `;
    }
  }}
`;

export const ScLink = styled(Link)`
  text-decoration: none;
` as any as typeof Link;
