import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="#fff"
      viewBox="0 0 20 20"
      {...props}
    >
      <path d="m15 3.142-1.475-1.475L5.192 10l8.333 8.334L15 16.858 8.142 10 15 3.142Z" />
    </svg>
  );
};
export default SvgArrowLeft;
