import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMinusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      viewBox="0 0 30 30"
      {...props}
    >
      <g clipPath="url(#minusIcon_svg__a)">
        <path fill="#fff" d="M6.6 13.8h16.8v2.4H6.6v-2.4Z" />
      </g>
      <defs>
        <clipPath id="minusIcon_svg__a">
          <path fill="#fff" d="M.6.6h28.8v28.8H.6z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgMinusIcon;
