import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="#fff"
      viewBox="0 0 20 20"
      {...props}
    >
      <path d="m5.192 16.858 1.475 1.475L15 10 6.667 1.667 5.192 3.142 12.05 10l-6.858 6.858Z" />
    </svg>
  );
};
export default SvgArrowRight;
