import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCameraFrame = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={304}
      height={306}
      fill="none"
      viewBox="0 0 304 306"
      {...props}
    >
      <g filter="url(#cameraFrame_svg__a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M60 6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v51a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V18a2 2 0 0 1 2-2h36a2 2 0 0 0 2-2V6Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#cameraFrame_svg__b)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M244 6a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2v51a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2V18a2 2 0 0 0-2-2h-36a2 2 0 0 1-2-2V6Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#cameraFrame_svg__c)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M294 242a2 2 0 0 1 2 2v48a2 2 0 0 1-2 2h-51a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h39a2 2 0 0 0 2-2v-36a2 2 0 0 1 2-2h8Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#cameraFrame_svg__d)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10 242a2 2 0 0 0-2 2v48a2 2 0 0 0 2 2h51a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H22a2 2 0 0 1-2-2v-36a2 2 0 0 0-2-2h-8Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <filter
          id="cameraFrame_svg__a"
          width={68}
          height={71}
          x={0}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="cameraFrame_svg__b"
          width={68}
          height={71}
          x={236}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="cameraFrame_svg__c"
          width={71}
          height={68}
          x={233}
          y={238}
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="cameraFrame_svg__d"
          width={71}
          height={68}
          x={0}
          y={238}
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgCameraFrame;
