import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Lottie from 'react-lottie';

import { Block } from 'components/UI/Block';
import { DocumentType } from 'api/document';
import { Button } from 'components/Button';
import { Icon } from 'components/UI/Icon';
import { Camera } from 'components/Camera';
import { ScCameraButtonBlock } from 'components/Camera/styled';
import { ManualCameraModal } from 'components/form/fileUpload/Camera';
import {
  IdCard,
  HandIdCard,
  Passport,
  HandPassport,
  IntPassport,
  HandIntPassport,
} from 'components/ThemedComponents/Documents';

import { DocumentCategory } from 'helpers/enums';
import { useMutable } from 'hooks/common';
import { useTheme } from 'hooks/theme';

import { useFormData } from '../../context';
import { CameraErrors } from '../../components/CameraErrorScreen/constants';
import { ManualPhotoBlock } from '../../components/ManualPhotoBlock';
import { CameraErrorScreen } from '../../components/CameraErrorScreen';

import {
  DocumentInspector,
  DocumentInspectorAction,
  DocumentInspectorEvent,
} from './inspector';
import {
  CAPTURE_OPTIONS,
  DOCUMENT_INSPECTION_MAX_SIZE,
  DOCUMENT_INSPECTION_THROTTLE,
  DOCUMENT_INSPECTION_TIMEOUT,
  TooltipText,
  getNextPageAnimationsList,
  nextPageActionTooltips,
} from './constants';
import {
  DocumentInspectorFormEvent,
  documentInspectorReducer,
  initialDocumentInspectorState,
} from './reducer';
import { ScDocumentMask, ScDocumentUploadForm } from './styled';

interface DocumentUploadFormProps {
  type: DocumentType;
  nextStep: () => void;
  prevStep: () => void;
}

const defaultMask = { ratio: 0.63, widthPercent: 70 };

export const DocumentInspectorForm: FC<DocumentUploadFormProps> = ({
  type,
  nextStep,
  prevStep,
}) => {
  const { t } = useTranslation('documents', { useSuspense: true });

  const theme = useTheme();

  const {
    data: { documentType },
    setDocumentFile,
  } = useFormData();

  const setDocumentFileRef = useMutable(setDocumentFile);

  const [documentInspectorState, dispatchDocumentInspectorAction] = useReducer(
    documentInspectorReducer,
    initialDocumentInspectorState
  );

  const mask = type.mask || defaultMask;

  const nextPageActionTooltip = useMemo(() => {
    const unicTooltip = nextPageActionTooltips[type.country]?.[type.category];
    return unicTooltip ? unicTooltip : TooltipText.rotateDocument;
  }, [type.category, type.country]);

  const uploadFormContent = useMemo(() => {
    switch (type.category) {
      case DocumentCategory.PASSPORT:
        const nextPageAnimationsList = getNextPageAnimationsList(theme);
        const nextPageAnimation =
          nextPageAnimationsList?.[type.country]?.[type.category];
        return {
          illustration: <Passport className="documentIllustration passport" />,
          tooltipIllustration: <HandPassport className="passport" />,
          mask: (
            <ScDocumentMask
              widthPercent={mask.widthPercent}
              aspectRatio={mask.ratio}
            >
              {documentInspectorState.tooltip === nextPageActionTooltip &&
                nextPageAnimation && (
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: nextPageAnimation,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                  />
                )}
              <Icon
                className={classnames('', {
                  success:
                    documentInspectorState.tooltip === TooltipText.completed,
                  rotated:
                    !nextPageAnimation &&
                    documentInspectorState.tooltip === nextPageActionTooltip,
                })}
                name="PassportMask"
              />
            </ScDocumentMask>
          ),
          title: t('documentsUpload.inspectorForm.passport.title'),
          text: t('documentsUpload.inspectorForm.passport.text'),
          label: t('documentsUpload.inspectorForm.passport.label'),
        };
      case DocumentCategory.FOREIGN_PASSPORT:
        return {
          illustration: (
            <IntPassport className="documentIllustration intPassport" />
          ),
          tooltipIllustration: <HandIntPassport className="intPassport" />,
          mask: (
            <ScDocumentMask
              widthPercent={mask.widthPercent}
              aspectRatio={mask.ratio}
            >
              <Icon name="IntPassportMask" />
            </ScDocumentMask>
          ),
          title: t('documentsUpload.inspectorForm.intPassport.title'),
          text: t('documentsUpload.inspectorForm.intPassport.text'),
          label: t('documentsUpload.inspectorForm.intPassport.label'),
        };
      case DocumentCategory.ID_CARD:
        return {
          illustration: <IdCard className="documentIllustration idCard" />,
          tooltipIllustration: <HandIdCard className="idCard" />,
          mask: (
            <ScDocumentMask
              widthPercent={mask.widthPercent}
              aspectRatio={mask.ratio}
            >
              <Icon
                name="DocumentIdMask"
                className={classnames('', {
                  rotated:
                    documentInspectorState.tooltip === nextPageActionTooltip,
                  success:
                    documentInspectorState.tooltip === TooltipText.completed,
                })}
              />
            </ScDocumentMask>
          ),
          title: t('documentsUpload.inspectorForm.idCard.title'),
          text: t('documentsUpload.inspectorForm.idCard.text'),
          label: t('documentsUpload.inspectorForm.idCard.label'),
        };
    }
  }, [
    type.category,
    type.country,
    mask,
    t,
    documentInspectorState.tooltip,
    nextPageActionTooltip,
    theme,
  ]);

  const inspectorRef = useRef<DocumentInspector>();

  useEffect(() => {
    if (
      !documentInspectorState.isStreaming ||
      !documentInspectorState.isReadyForInspection ||
      documentInspectorState.error != null
    )
      return;

    const inspector = new DocumentInspector(
      type.country,
      type.category,
      type.maxPagesCount,
      mask,
      DOCUMENT_INSPECTION_MAX_SIZE,
      DOCUMENT_INSPECTION_TIMEOUT
    );

    inspectorRef.current = inspector;

    function handleInspectorAction(action: DocumentInspectorAction) {
      switch (action.type) {
        case DocumentInspectorEvent.START:
          dispatchDocumentInspectorAction({
            type: DocumentInspectorFormEvent.CHANGE_TOOLTIP,
            payload: TooltipText.placeDocument,
          });
          break;
        case DocumentInspectorEvent.NEXT_PAGE:
          dispatchDocumentInspectorAction({
            type: DocumentInspectorFormEvent.CHANGE_TOOLTIP,
            payload: TooltipText.completed,
          });
          setTimeout(() => {
            dispatchDocumentInspectorAction({
              type: DocumentInspectorFormEvent.CHANGE_TOOLTIP,
              payload: nextPageActionTooltip,
            });
          }, 2000);
          break;
        case DocumentInspectorEvent.FINISH:
          action.payload.forEach((page, index) =>
            setDocumentFileRef.current(
              new File([page], `page${index}.jpg`),
              `page${index}`,
              false
            )
          );

          nextStep();
          break;
        case DocumentInspectorEvent.TIMEOUT:
          dispatchDocumentInspectorAction({
            type: DocumentInspectorFormEvent.ERROR,
            payload: CameraErrors.TIMEOUT,
          });
          break;
      }
    }

    inspector.subscribe(handleInspectorAction);

    return () => {
      inspector.unsubscribe(handleInspectorAction);

      inspectorRef.current = undefined;
    };
  }, [
    type,
    mask,
    documentInspectorState.isStreaming,
    documentInspectorState.isReadyForInspection,
    documentInspectorState.error,
    setDocumentFileRef,
    nextStep,
    nextPageActionTooltip,
  ]);

  const processFrame = useMemo(
    () =>
      throttle(
        (frame: HTMLVideoElement) => inspectorRef.current?.processFrame(frame),
        DOCUMENT_INSPECTION_THROTTLE
      ),
    []
  );

  const closeModal = useCallback(
    () =>
      dispatchDocumentInspectorAction({
        type: DocumentInspectorFormEvent.RESET,
        payload: initialDocumentInspectorState,
      }),
    []
  );

  const handleNotAvailable = useCallback(() => {
    dispatchDocumentInspectorAction({
      type: DocumentInspectorFormEvent.ERROR,
      payload: CameraErrors.CAMERA_NOT_AVAILABLE,
    });
  }, []);

  const handleStreamingEvent = useCallback(() => {
    dispatchDocumentInspectorAction({
      type: DocumentInspectorFormEvent.STREAMING,
    });
  }, []);

  if (uploadFormContent == null || documentType == null) return null;

  return (
    <>
      {documentInspectorState.error ? (
        <CameraErrorScreen
          tryAgainHandler={() => {
            dispatchDocumentInspectorAction({
              type: DocumentInspectorFormEvent.RESET,
              payload: {
                ...initialDocumentInspectorState,
                isCameraModalOpen: true,
              },
            });
          }}
          openManualHandler={() => {
            dispatchDocumentInspectorAction({
              type: DocumentInspectorFormEvent.MANUAL,
              payload: true,
            });
          }}
          cameraError={documentInspectorState.error}
        />
      ) : (
        <Block
          button={
            <Button
              icon={<Icon name="PhotoCamera" />}
              onClick={() =>
                dispatchDocumentInspectorAction({
                  type: DocumentInspectorFormEvent.OPEN_MODAL,
                })
              }
              type="button"
            >
              {t('documentsUpload.inspectorForm.makePhoto')}
            </Button>
          }
          onBack={prevStep}
        >
          <ScDocumentUploadForm>
            {uploadFormContent.illustration}
            <h4>{uploadFormContent.title}</h4>
            <p className="body1">{uploadFormContent.text}</p>
          </ScDocumentUploadForm>
          {documentInspectorState.isCameraModalOpen && (
            <ManualCameraModal
              title={uploadFormContent.label}
              onClose={closeModal}
            >
              <Camera
                onNotAvailable={handleNotAvailable}
                captureOptions={CAPTURE_OPTIONS}
                tooltipIllustration={
                  documentInspectorState.isReadyForInspection
                    ? undefined
                    : uploadFormContent.tooltipIllustration
                }
                mask={uploadFormContent.mask}
                getMediaStream={handleStreamingEvent}
                onFrame={processFrame}
              >
                <ScCameraButtonBlock>
                  <p className="body1 text">
                    {t(documentInspectorState.tooltip)}
                  </p>
                  {!documentInspectorState.isReadyForInspection ? (
                    <Button
                      onClick={() =>
                        dispatchDocumentInspectorAction({
                          type: DocumentInspectorFormEvent.START_INSPECTION,
                        })
                      }
                      type="button"
                      disabled={!documentInspectorState.isStreaming}
                    >
                      {t('documentsUpload.inspectorForm.areReady')}
                    </Button>
                  ) : (
                    <p
                      className="makeManualPhoto"
                      onClick={() =>
                        dispatchDocumentInspectorAction({
                          type: DocumentInspectorFormEvent.ERROR,
                          payload: CameraErrors.NOT_SCANNED,
                        })
                      }
                    >
                      <Icon name="HelpIcon" />
                      {t('documentsUpload.inspectorForm.makeManual')}
                    </p>
                  )}
                </ScCameraButtonBlock>
              </Camera>
            </ManualCameraModal>
          )}
        </Block>
      )}

      {documentInspectorState.isManual && (
        <ManualPhotoBlock
          type={type}
          onClose={() => {
            dispatchDocumentInspectorAction({
              type: DocumentInspectorFormEvent.MANUAL,
              payload: false,
            });
          }}
          onNotAvailable={handleNotAvailable}
          nextStep={nextStep}
        />
      )}
    </>
  );
};
