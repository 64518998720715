import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      viewBox="0 0 30 30"
      {...props}
    >
      <g clipPath="url(#PlusIcon_svg__a)">
        <path
          fill="#fff"
          d="M13.8 13.8V6.6h2.4v7.2h7.2v2.4h-7.2v7.2h-2.4v-7.2H6.6v-2.4h7.2Z"
        />
      </g>
      <defs>
        <clipPath id="PlusIcon_svg__a">
          <path fill="#fff" d="M.6.6h28.8v28.8H.6z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPlusIcon;
