// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { ImageMap } from '../contracts';

import logo, { ReactComponent as JSXlogo } from './images/logo.svg';
import mobileLogo, {
  ReactComponent as JSXmobileLogo,
} from './images/mobileLogo.svg';

export const images: ImageMap = {
  logo: { path: logo, component: JSXlogo },
  mobileLogo: { path: mobileLogo, component: JSXmobileLogo },
};
