import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from 'components/UI/Block';
import { Icon } from 'components/UI/Icon';

import { DocumentCategory } from 'helpers/enums';

import { DocumentsRadioGroup } from '../../components/DocumentsRadioGroup';
import { useFormData } from '../context';

export interface DocumentTypeSelectorProps {
  categories: DocumentCategory[];
  nextStep: () => void;
}

export const DocumentTypeSelector: FC<DocumentTypeSelectorProps> = ({
  categories,
  nextStep,
}) => {
  const { t } = useTranslation('documents', { useSuspense: true });
  const { setFormValues } = useFormData();

  const icons = {
    [DocumentCategory.PASSPORT]: <Icon name="PassportCateg" />,
    [DocumentCategory.FOREIGN_PASSPORT]: <Icon name="PassportIntCateg" />,
    [DocumentCategory.ID_CARD]: <Icon name="IdCardCateg" />,
  };

  const documents = categories.map((category) => ({
    name: t(`typeSelector.types.${category}`),
    value: category,
    icon: icons[category],
  }));

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
        documentType: parseInt(event.target.value),
        documents: {},
      });
      nextStep();
    },
    [nextStep, setFormValues]
  );

  return (
    <Block
      title={t('typeSelector.title')}
      description={t('typeSelector.description')}
    >
      <DocumentsRadioGroup
        registerName="documentType"
        documents={documents}
        onChange={handleChange}
      />
    </Block>
  );
};
