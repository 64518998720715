import React, { FC } from 'react';

import { Icon } from 'components/UI/Icon';
import { BlockProps } from './types';
import * as Styled from './styled';

const Block: FC<BlockProps> = ({
  children,
  title,
  description,
  button,
  onBack,
  flexGap,
}) => {
  return (
    <Styled.Block>
      {onBack && (
        <Styled.BackBtn type="button" onClick={onBack}>
          <Icon name="ArrowLeft" />
        </Styled.BackBtn>
      )}

      <Styled.Content flexGap={flexGap}>
        {(title != null || description != null) && (
          <Styled.HeaderInfo>
            {title && <h4>{title}</h4>}
            {description && <p>{description}</p>}
          </Styled.HeaderInfo>
        )}
        {children}
        {button != null && <Styled.ButtonBlock>{button}</Styled.ButtonBlock>}
      </Styled.Content>
    </Styled.Block>
  );
};

export default Block;
