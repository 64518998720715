import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScHeader = styled.header`
  .header {
    padding: 32px 86px;
    max-width: 1440px;
    width: 100%;
    @media (${device.tablet}) {
      padding: 16px;
    }
    &__row {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
    &__logo {
      display: flex;
      height: 36px;
      width: auto;
      @media (${device.tablet}) {
        height: 24px;
        max-width: 180px;
      }
    }
  }
`;
