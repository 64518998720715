import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="#D8D8D8"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 16H5V10h14v10Z" />
      <path d="m14.882 12-4.08 3.864-1.684-1.578L8 15.333 10.802 18 16 13.088 14.882 12Z" />
    </svg>
  );
};
export default SvgCalendarIcon;
