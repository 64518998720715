import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';
import { ScButton } from 'components/Button/styled';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${({ theme }) => theme.base_100};
  border: 1px solid ${({ theme }) => theme.base_300};
  max-width: 620px;
  width: 100%;
  border-radius: 12px;
  padding: 56px 36px;
  position: relative;
  @media (${device.mobileXL}) {
    padding: 24px 16px;
  }
`;

export const BackBtn = styled.button`
  position: absolute;
  top: 16px;
  display: block;
  background-color: transparent;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  svg path {
    fill: ${({ theme }) => theme.baseContent};
  }
  @media (${device.mobileXL}) {
    position: static;
  }
`;

export const Content = styled.div<{ flexGap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ flexGap }) => (flexGap ? `${flexGap}px` : '40px')};
  @media (${device.mobileXL}) {
    gap: 20px;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  h4 {
    font: ${({ theme }) => '700 28px/32px ' + theme.mainFontFamilyName};
    color: ${({ theme }) => theme.baseContent};
    @media (${device.mobileXL}) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  p {
    color: ${({ theme }) => theme.baseSecondaryContent};
  }
`;

export const ButtonBlock = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
  column-gap: 12px;
  ${ScButton}:not(:only-child) {
    flex-grow: 1;
  }
`;
