import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useErrorHandlerFactory } from 'hooks/error';
import { useApi } from 'hooks/api';
import { SendLinkParams, sendVerificationLink } from 'api/verification';

import { Block } from 'components/UI/Block';
import { FormInputBlock } from 'components/form/input';
import { Button } from 'components/Button';
import { ScWrap } from 'components/styles/common';

import { showTooltip } from 'helpers/tooltip';
import { parseErrorCode, parseErrorData } from 'helpers/api';
import { mailValidator } from 'helpers/validators';
import { capitalize } from 'helpers/common';
import { useCountdown } from 'hooks/interval';

import { TooltipTypes } from 'modules/globalTooltip';

import { ScMailBlock } from './styled';

const timeoutErrorCode = 0;

const MailBlock: FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  const [sendLink, { pending }] = useApi(sendVerificationLink, undefined);

  const { handleSubmit, control } = useForm<SendLinkParams>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      lang: language,
    },
  });

  const [countdown, reset] = useCountdown(0);

  const sendLinkErrorMapping = useMemo(
    () => ({
      0: 'validation:timeout',
      1: 'validation:userInteractedCompleted',
    }),
    []
  );

  const loginErrorHandler = useErrorHandlerFactory(sendLinkErrorMapping);

  const onSubmit = useCallback(
    async (values: SendLinkParams) => {
      values.lang = language;

      const { error, response } = await sendLink(values).request;

      if (response != null) {
        reset(response.data.timeout);
        showTooltip({
          type: TooltipTypes.SUCCESS,
          body: capitalize(t('successfully')),
        });
      } else {
        const errorCode = parseErrorCode(error);
        const errorData = parseErrorData(error);

        if (errorCode === timeoutErrorCode) reset(errorData.timeout);

        showTooltip({
          type: TooltipTypes.ERROR,
          body: loginErrorHandler(errorCode),
        });
      }
    },
    [loginErrorHandler, reset, sendLink, language, t]
  );

  return (
    <Block onBack={onClose} title={t('request.mail.sendLinkToEmail')}>
      <form>
        <ScMailBlock>
          <p className="content">{t('request.mail.enterEmail')}</p>

          <FormInputBlock
            control={control}
            name="email"
            autoComplete="email"
            rules={{ validate: mailValidator }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit(onSubmit);
              }
            }}
            label={<p>{t('email')}</p>}
          />

          <ScWrap topMargin="36" fullWidth>
            <Button
              disabled={pending || countdown > 0}
              className="sbmt-btn"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              {countdown <= 0
                ? capitalize(t('send'))
                : t('seconds', { val: countdown })}
            </Button>
          </ScWrap>
        </ScMailBlock>
      </form>
    </Block>
  );
};

export default memo(MailBlock);
