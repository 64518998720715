import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { DocumentType, uploadDocument } from 'api/document';
import {
  DocumentCategory,
  DocumentErrorCode,
  VerificationStageTypes,
} from 'helpers/enums';
import { useApi } from 'hooks/api';
import { Block } from 'components/UI/Block';
import { Loader } from 'components/Loader';
import { showTooltip } from 'helpers/tooltip';
import { TooltipTypes } from 'modules/globalTooltip';
import { translationFactory } from 'helpers/trans';
import { parseErrorCode } from 'helpers/api';
import { getCurrentStage, GetCurrentStageResponse } from 'api/verification';

import { ResultErrorScreen } from '../components/ResultErrorScreen';

import { Step } from './constants';
import { DocumentTypeSelector } from './steps/TypeSelector';
import { DocumentInspectorForm } from './steps/InspectorForm';
import { RequirementsForDocument } from './steps/Requirements';
import { CheckDocuments } from './steps/CheckDocuments';
import { FormProvider, useFormData } from './context';
import { DocumentUploadForm } from './steps/Upload';

export interface DocumentsUploadForm {
  documentType: DocumentCategory;
  documents: { [key: string]: File };
}

const DocumentsUpload: FC = () => {
  const [errorCode, setErrorCode] = useState<DocumentErrorCode | null>(null);

  const [step, setStep] = useState<Step>(Step.TYPE_SELECTOR);

  const { documentTypes, currentStage } = useLoaderData() as {
    documentTypes: DocumentType[];
    currentStage: {
      stageInfo: GetCurrentStageResponse;
      stageRedirect?: JSX.Element;
    };
  };

  const { country } = useParams();

  const { handleSubmit } = useForm<DocumentsUploadForm>();

  const {
    data: { documents, documentType, manual },
  } = useFormData();

  const navigate = useNavigate();

  const { t } = useTranslation('documents');

  const [callUploadDocument, { errorData, pending }, { dispatch }] = useApi(
    uploadDocument,
    undefined,
    {
      pendingOnSuccess: true,
    }
  );

  if (!documentTypes) return null;

  if (currentStage?.stageRedirect) return currentStage.stageRedirect;

  const handleNextStep = () => {
    if (step !== Step.CHECK_DOCUMENTS) setStep((prevState) => prevState + 1);
  };

  const handlePrevStep = () => {
    if (step !== Step.TYPE_SELECTOR) setStep((prevState) => prevState - 1);
  };

  const onSubmit = async () => {
    const type = documentTypes.find((type) => type.category === documentType);

    if (documentType == null || type == null) return;

    if (documents == null) {
      showTooltip({
        type: TooltipTypes.ERROR,
        translate: true,
        body: translationFactory(
          'documents:documentsUpload.errors.invalidPagesCount'
        ),
      });
      return;
    }

    const files = Object.entries(documents)
      .sort(function ([a], [b]) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .map(([_, file]) => file as File)
      .slice(0, type.maxPagesCount);

    if (
      !(
        type.minPagesCount <= files.length && files.length <= type.maxPagesCount
      )
    ) {
      showTooltip({
        type: TooltipTypes.ERROR,
        translate: true,
        body: translationFactory(
          'documents:documentsUpload.errors.invalidPagesCount'
        ),
      });
      return;
    }

    const { response, error } = await callUploadDocument(
      country as string,
      documentType,
      files,
      manual
    ).request;

    if (response == null) {
      const errorCode = parseErrorCode(error);
      setErrorCode(errorCode);
      dispatch({ type: 'PENDING', payload: false });
      return;
    }

    const { id } = response.data;

    navigate(`/documents/${id}/edit`);
  };

  return pending ? (
    <Block>
      <Loader relative small borderRadius={'12px'} />
    </Block>
  ) : errorCode != null ? (
    <ResultErrorScreen
      errorCode={errorCode}
      documentId={errorData.id}
      textSecondaryButton={
        [
          DocumentErrorCode.PRIMARY_DOCUMENT_EXISTS,
          DocumentErrorCode.UPLOAD_ATTEMPTS_EXCEEDED,
        ].includes(errorCode)
          ? t('documentsUpload.continue')
          : t('documentsUpload.retry')
      }
      onClickSecondaryButton={async () => {
        [
          DocumentErrorCode.PRIMARY_DOCUMENT_EXISTS,
          DocumentErrorCode.UPLOAD_ATTEMPTS_EXCEEDED,
        ].includes(errorCode) &&
          (await getCurrentStage().then((r) => {
            if (r.data.type !== VerificationStageTypes.DOCUMENT_UPLOAD) return;

            navigate(`/documents/${r.data.uploadedDocumentId}/edit`);
          }));
        setErrorCode(null);
      }}
    />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {step === Step.TYPE_SELECTOR && (
        <DocumentTypeSelector
          nextStep={handleNextStep}
          categories={documentTypes.map(({ category }) => category)}
        />
      )}
      {step === Step.REQUIREMENTS_FOR_DOCUMENT && (
        <RequirementsForDocument
          nextStep={handleNextStep}
          prevStep={handlePrevStep}
        />
      )}
      {step === Step.UPLOAD && (
        <DocumentUploadStep
          types={documentTypes}
          nextStep={handleNextStep}
          prevStep={handlePrevStep}
        />
      )}
      {step === Step.CHECK_DOCUMENTS && (
        <CheckDocuments types={documentTypes} prevStep={handlePrevStep} />
      )}
    </form>
  );
};

interface DocumentUploadStepProps {
  types: DocumentType[];
  nextStep: () => void;
  prevStep: () => void;
}

const DocumentUploadStep: FC<DocumentUploadStepProps> = ({
  types,
  ...rest
}) => {
  const {
    data: { documentType },
  } = useFormData();

  const type = types.find(({ category }) => category === documentType);

  if (type == null) return null;

  if (type.isInspectionAvailable) {
    return <DocumentInspectorForm type={type} {...rest} />;
  }

  return <DocumentUploadForm type={type} {...rest} />;
};

const DocumentsUploadWithContext: FC = () => (
  <FormProvider>
    <DocumentsUpload />
  </FormProvider>
);

export default memo(DocumentsUploadWithContext);
