import React, { FC, useState } from 'react';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

import { Block } from 'components/UI/Block';
import { copyWithTooltip } from 'helpers/copy';
import { createVerificationLink } from 'helpers/common';
import InternalError from 'components/internal/InternalError';
import { Modal } from 'components/Modal';
import { Icon } from 'components/UI/Icon';
import { Button } from 'components/Button';
import { ScWrap } from 'components/styles/common';
import { auth } from 'services/auth';

import MailBlock from './components/mailBlock';
import { ScRedirectBlock } from './styled';

enum RedirectType {
  QR = 'qr',
  MAIL = 'mail',
}

export const RedirectBlock: FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('common');

  const [redirectType, setRedirectType] = useState<RedirectType>(
    RedirectType.QR
  );

  const token = auth.get();

  if (!token) return <InternalError />;

  const linkToContinueVerification = createVerificationLink(token);

  return (
    <>
      {redirectType === RedirectType.QR ? (
        <Block onBack={onClose} title={t('request.verificationLink')}>
          <ScRedirectBlock>
            <p className="body1">{t('request.text')}</p>
            <div className="qr">
              <QRCode size={194} value={linkToContinueVerification} />
            </div>

            <div className="linkToCopy">
              <p className="linkToCopy__label">
                {t('request.verificationLink')}
              </p>
              <div>
                <p>{linkToContinueVerification}</p>{' '}
                <Icon
                  className="copyIcon"
                  name="CopyBadge"
                  onClick={() => {
                    copyWithTooltip(linkToContinueVerification);
                  }}
                />
              </div>
            </div>

            <ScWrap topMargin="20">
              <Button
                onClick={() => setRedirectType(RedirectType.MAIL)}
                asLink
                icon={<Icon name="MailBadge" />}
              >
                {t('common:request.mail.sendLinkToEmail')}
              </Button>
            </ScWrap>
          </ScRedirectBlock>
        </Block>
      ) : (
        <MailBlock onClose={() => setRedirectType(RedirectType.QR)} />
      )}
    </>
  );
};

export const RedirectModal: FC<{ onClose?: () => void }> = ({ onClose }) => (
  <Modal onClose={onClose}>
    <RedirectBlock />
  </Modal>
);
