import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from 'components/UI/Block';
import { Button } from 'components/Button';

import { RequirementsList } from './RequirementsList';

interface RequirementsForDocumentProps {
  nextStep: () => void;
  prevStep: () => void;
}

export const RequirementsForDocument: FC<RequirementsForDocumentProps> = ({
  nextStep,
  prevStep,
}) => {
  const { t } = useTranslation('documents');

  return (
    <Block
      title={t('requirementsForDocument.title')}
      description={t('requirementsForDocument.requirementsTitle')}
      button={
        <Button type="button" onClick={nextStep}>
          {t('requirementsForDocument.button')}
        </Button>
      }
      onBack={prevStep}
    >
      <RequirementsList />
    </Block>
  );
};
