import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDocumentIdMask = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={329}
      height={207}
      fill="none"
      viewBox="0 0 329 207"
      {...props}
    >
      <g filter="url(#documentIdMask_svg__a)" opacity={0.4}>
        <path
          fill="#fff"
          d="M48.974 75.204c0 12.618 10.242 22.887 22.828 22.887 12.586 0 22.829-10.269 22.829-22.887S84.388 52.317 71.802 52.317 48.974 62.586 48.974 75.204Zm63.412 73.747h5.073v-5.086c0-19.627-15.934-35.602-35.51-35.602H61.655c-19.581 0-35.51 15.975-35.51 35.602v5.086h86.24Z"
        />
      </g>
      <defs>
        <filter
          id="documentIdMask_svg__a"
          width={91.314}
          height={100.634}
          x={26.145}
          y={52.317}
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.49 0" />
          <feBlend in2="shape" result="effect1_innerShadow_2_23" />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgDocumentIdMask;
